import React from 'react';

const Search = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="18.057"
    height="18.057"
    viewBox="0 0 18.057 18.057"
  >
    <g transform="translate(0)">
      <path
        d="M321.274,536.46l4.385-4.386a7.5,7.5,0,1,1,1.324,1.324l-4.386,4.385a.937.937,0,0,1-1.323,0h0A.937.937,0,0,1,321.274,536.46Zm10.3-3.356a5.616,5.616,0,1,0-3.97-1.645A5.579,5.579,0,0,0,331.569,533.1Z"
        transform="translate(-321 -520)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default Search;
